export const environment = {
  production: true,
  baseUrl: 'https://qa.inclusive.health/services/v1/',
  socketConnectionUrl: 'https://qa.trialengine.ai/',
  frontEndUrl: 'https://qa.inclusive.health/',
  inclusiveUrl: 'https://qa.inclusive.health/',
  rgridUrl: 'https://qa.trialengine.ai/',
  mixPanelToken: '6392215eb8e08b3d7050694ffa61327e',
  DOMAIN_URL: 'inclusive.health',
  loadingGif: 'inclusive_health/ih_loader.gif',
  favIcon: 'https://rgrid-public-files.s3.eu-west-2.amazonaws.com/deployment/inclusive_favicon.ico',
  title: 'Inclusive',
  logo: 'inclusive_health/inclusive_health_logo-tm.png',
  footer: 'inclusive_health/inclusive_health_logo-tm.png',
  privacy_URL: 'inclusive_privacy_policy.pdf',
  terms_URL: 'incluive_terms_conditions.pdf',
  cookie_policy: 'Inclusive_Cookie_Policy.pdf',
  security_information: 'security_information_Inclusive_participants.pdf',
  themeColor: '#B4526a',
  defaultColor: '#B4526a',
  signup_form_key: '6e2e@6ch1&d',
  googleAnalyticsCode: 'G-FXEVBQQL5J',
  CRYPTR_SECRET: 'KaPdSgVkY3pv8ys5',
  firebaseConfig: {
    apiKey: 'AIzaSyD8b_aSau4fttteMKIlrV0p6L6XB9sDt3c',
    authDomain: 'rgrid-84303.firebaseapp.com',
    databaseURL: 'https://rgrid-84303-default-rtdb.firebaseio.com',
    projectId: 'rgrid-84303',
    storageBucket: 'rgrid-84303.appspot.com',
    messagingSenderId: '456851976900',
    appId: '1:456851976900:web:ca338821d3b712beefcf91',
    measurementId: 'G-ZL4B4C1WYR'
  },
  INTEGRATIONS: {
    GOOGLE: {
      CLIENT_ID: '423770406749-hsfrtdpoomau8j6hr8p7deeegpk9gdom.apps.googleusercontent.com',
      APP_ID: 'inclusive-333912'
    }
  },
  versionControl: {
    env: 'qa_inclusive',
    v: '4_2v',
    secure: false,
    sameSite: 'strict'
  },
  stripe: {
    usd: 'pk_test_51K6zLPCV0zhZdUGs63R0CSwLTO9c8qh6MqsEB6oGdWMZtZcyG7QRmtokFIGdiSRZ1xxrQ0M4f4hkpeTTC1E7Z5Ts00cgo4brts',
    gbp: 'pk_test_51IBNinDI2kVfFrr1W3z4qP6FQmds9VPFtWA3Mb3kJjrcwD1LudCCnXkoGEFIb955zWGaSsOya1qV6Lkcxv5cWTmE00WUkelRl1',
    eur: 'pk_test_51K6zqdKRGNvrAZClvJMTij44jdRnE1tVZ8eCwBu9WkeLJHQsxDIMUGegS7KdaOe6X0myuzxhMtoj2bGg0pEywU2T009Ml5z2Kl'
  },
  demoLink: 'https://calendly.com/inclusive-meeting',
  ASSET_CDN: 'https://qa.inclusive.health/assets/'
};
